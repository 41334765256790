<template>
  <div>
    <div class="header-text pb-3" data-cy="header-admin-reports">Reports</div>
    <div class="box-style mb-6">
      <div class="mb-2">
        <svg
          class="inline-block mr-2"
          id="icon-flag"
          xmlns="http://www.w3.org/2000/svg"
          width="14"
          height="14"
          viewBox="0 0 16 16"
        >
          <path
            id="flag"
            d="M10.505,2.5c-1.535,0-2.916-1-5.06-1a6.936,6.936,0,0,0-2.523.474A1.5,1.5,0,1,0,.75,2.8V15.5a.5.5,0,0,0,.5.5h.5a.5.5,0,0,0,.5-.5V12.892A8.6,8.6,0,0,1,6.245,12c1.535,0,2.916,1,5.06,1a7.26,7.26,0,0,0,4.017-1.249A1.5,1.5,0,0,0,16,10.5V3a1.5,1.5,0,0,0-2.091-1.379A8.938,8.938,0,0,1,10.505,2.5Zm3.995,8a5.878,5.878,0,0,1-3.2,1c-1.873,0-3.188-1-5.06-1a10.719,10.719,0,0,0-3.995.75V4a5.878,5.878,0,0,1,3.2-1c1.873,0,3.188,1,5.06,1A10.685,10.685,0,0,0,14.5,3Z"
            transform="translate(0 0)"
          />
        </svg>
        <div class="thirdhead-text inline-block">Student</div>
      </div>
      <div class="report_box grid gap-8 grid-cols-3">
        <div class="">
          <router-link to="/admin/reports/student_export">
            <button
              class="bg-white border border-cool-gray w-full rounded-md py-2"
            >
              Student Export
            </button>
          </router-link>
        </div>
        <div class="">
          <router-link to="/admin/reports/interest_pairing_factors">
            <button
              class="bg-white border border-cool-gray w-full rounded-md py-2"
            >
              Interest Areas / Pairing Factors
            </button>
          </router-link>
        </div>
        <div class="">
          <router-link to="/admin/reports/milestones">
            <button
              class="bg-white border border-cool-gray w-full rounded-md py-2"
            >
              Milestone Report
            </button>
          </router-link>
        </div>
      </div>
    </div>

    <div class="box-style mb-6">
      <div class="mb-2">
        <svg
          class="inline-block mr-2"
          id="icon-flag"
          xmlns="http://www.w3.org/2000/svg"
          width="14"
          height="14"
          viewBox="0 0 16 16"
        >
          <path
            id="flag"
            d="M10.505,2.5c-1.535,0-2.916-1-5.06-1a6.936,6.936,0,0,0-2.523.474A1.5,1.5,0,1,0,.75,2.8V15.5a.5.5,0,0,0,.5.5h.5a.5.5,0,0,0,.5-.5V12.892A8.6,8.6,0,0,1,6.245,12c1.535,0,2.916,1,5.06,1a7.26,7.26,0,0,0,4.017-1.249A1.5,1.5,0,0,0,16,10.5V3a1.5,1.5,0,0,0-2.091-1.379A8.938,8.938,0,0,1,10.505,2.5Zm3.995,8a5.878,5.878,0,0,1-3.2,1c-1.873,0-3.188-1-5.06-1a10.719,10.719,0,0,0-3.995.75V4a5.878,5.878,0,0,1,3.2-1c1.873,0,3.188,1,5.06,1A10.685,10.685,0,0,0,14.5,3Z"
            transform="translate(0 0)"
          />
        </svg>
        <div class="thirdhead-text inline-block">Mentors</div>
      </div>
      <div class="report_box grid gap-8 grid-cols-3">
        <div class="">
          <router-link to="/admin/reports/mentors_status">
            <button
              class="bg-white border border-cool-gray w-full rounded-md py-2"
            >
              Mentors by Status
            </button>
          </router-link>
        </div>
        <div>
          <router-link to="/admin/reports/retired_mentors">
            <button
              class="bg-white border border-cool-gray w-full rounded-md py-2"
            >
              Retired
            </button>
          </router-link>
        </div>
        <div>
          <router-link to="/admin/reports/mentor_scores">
            <button
              class="bg-white border border-cool-gray w-full rounded-md py-2"
            >
              Mentor Survey Scores
            </button>
          </router-link>
        </div>
      </div>
    </div>

    <div class="box-style mb-6">
      <div class="mb-2">
        <svg
          class="inline-block mr-2"
          id="icon-flag"
          xmlns="http://www.w3.org/2000/svg"
          width="14"
          height="14"
          viewBox="0 0 16 16"
        >
          <path
            id="flag"
            d="M10.505,2.5c-1.535,0-2.916-1-5.06-1a6.936,6.936,0,0,0-2.523.474A1.5,1.5,0,1,0,.75,2.8V15.5a.5.5,0,0,0,.5.5h.5a.5.5,0,0,0,.5-.5V12.892A8.6,8.6,0,0,1,6.245,12c1.535,0,2.916,1,5.06,1a7.26,7.26,0,0,0,4.017-1.249A1.5,1.5,0,0,0,16,10.5V3a1.5,1.5,0,0,0-2.091-1.379A8.938,8.938,0,0,1,10.505,2.5Zm3.995,8a5.878,5.878,0,0,1-3.2,1c-1.873,0-3.188-1-5.06-1a10.719,10.719,0,0,0-3.995.75V4a5.878,5.878,0,0,1,3.2-1c1.873,0,3.188,1,5.06,1A10.685,10.685,0,0,0,14.5,3Z"
            transform="translate(0 0)"
          />
        </svg>
        <div class="thirdhead-text inline-block">Pairing</div>
      </div>
      <div class="report_box grid gap-8 grid-cols-3">
        <div>
          <router-link to="/admin/reports/pairings">
            <button
              class="bg-white border border-cool-gray w-full rounded-md py-2"
            >
              Mentor/Student Pairings
            </button>
          </router-link>
        </div>
        <div>
          <router-link to="/admin/reports/auto_pairing">
            <button
              class="bg-white border border-cool-gray w-full rounded-md py-2"
            >
              Auto Pairing
            </button>
          </router-link>
        </div>
        <div>
          <router-link to="/admin/reports/survey_results">
            <button
              class="bg-white border border-cool-gray w-full rounded-md py-2"
            >
              Survey Results
            </button>
          </router-link>
        </div>
      </div>
    </div>

    <div class="box-style mb-6">
      <div class="mb-2">
        <svg
          class="inline-block mr-2"
          id="icon-flag"
          xmlns="http://www.w3.org/2000/svg"
          width="14"
          height="14"
          viewBox="0 0 16 16"
        >
          <path
            id="flag"
            d="M10.505,2.5c-1.535,0-2.916-1-5.06-1a6.936,6.936,0,0,0-2.523.474A1.5,1.5,0,1,0,.75,2.8V15.5a.5.5,0,0,0,.5.5h.5a.5.5,0,0,0,.5-.5V12.892A8.6,8.6,0,0,1,6.245,12c1.535,0,2.916,1,5.06,1a7.26,7.26,0,0,0,4.017-1.249A1.5,1.5,0,0,0,16,10.5V3a1.5,1.5,0,0,0-2.091-1.379A8.938,8.938,0,0,1,10.505,2.5Zm3.995,8a5.878,5.878,0,0,1-3.2,1c-1.873,0-3.188-1-5.06-1a10.719,10.719,0,0,0-3.995.75V4a5.878,5.878,0,0,1,3.2-1c1.873,0,3.188,1,5.06,1A10.685,10.685,0,0,0,14.5,3Z"
            transform="translate(0 0)"
          />
        </svg>
        <div class="thirdhead-text inline-block">Experiences</div>
      </div>
      <div class="report_box grid gap-8 grid-cols-3">
        <div>
          <router-link to="/admin/reports/usage_report">
            <button
              class="bg-white border border-cool-gray w-full rounded-md py-2"
            >
              Usage Report
            </button>
          </router-link>
        </div>
        <div>
          <router-link to="/admin/reports/usage_descriptions">
            <button
              class="bg-white border border-cool-gray w-full rounded-md py-2"
            >
              Usage Descriptions
            </button>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Reports",
};
</script>

<style scoped>
.report_box {
  @apply rounded-md p-5 border border-cool-gray bg-cool-white;
}
</style>
